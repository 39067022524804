/** 
 *   合伙人
 */
import { get, post } from '@/untils/js/axios.js'

// 查询是否是合伙人
export const checkPartner = params => get('/partner/applystatus', params)

// 申请合伙人
export const applicationPartner = params => post('/partner/apply', params)

// 升级企业合伙人
export const applicationqyPartner = params => post('/partner/upgrade', params)

// 重新申请合伙人
export const reApplicationPartner = params => post('/partner/setviewstatus', params)
