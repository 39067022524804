<template>
  <section class="m-partner m-scroll">
    <!-- 头部导航 -->
    <m-header title="合伙人申请"></m-header>
    <van-tabs v-model="tab" animated title-active-color="#ED2A24" line-width="32px" line-height="2px" :border="false"  swipeable v-if="status == 0">
        <van-tab title="企业合伙人">
          <div class="form">
            <p class="label">企业名称</p>
            <input v-model="form.name"  type="text" class="reset-input" placeholder="请输入">

            <p class="label">企业邮箱</p>
            <input v-model="form.email" type="text" class="reset-input" placeholder="请输入">

            <p class="label">联系人</p>
            <div class="input-box">
              <input v-model="form.firstname" type="text" class="reset-input" placeholder="名称">
              <input v-model="form.lastname"  type="text" class="reset-input" placeholder="姓氏">
            </div>

            <p class="label">联系电话</p>
            <div class="input-phone">
              <div @click="telPicker = true">
                <img src="@/assets/public/icon_jiananda@2x.png" alt="canada" v-if="form.phoneType == '1'">
                <img src="@/assets/public/china.png" alt="canada" v-else>
                <span>+{{form.phoneType}}</span>
              </div>
              <input v-model="form.phone" type="text" >
            </div>

            <div class="footer">
              <van-button @click="onNext" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">提交审核</van-button>
              <!-- <van-checkbox v-model="agree" icon-size="12px" checked-color="#F03C18"  style="margin-top:10px">
                <p class="times">阅读并同意 <span>《成为合伙人规则条件》</span></p>
              </van-checkbox>  -->
            </div>
          </div>
        </van-tab>

        <van-tab title="个人合伙人" :disabled="disable">
          <div class="form">

            <p class="label">联系邮箱</p>
            <input v-model="form.email" type="text" class="reset-input" placeholder="请输入">

            <p class="label">联系人</p>
            <div class="input-box">
              <input v-model="form.firstname" type="text" class="reset-input" placeholder="名称">
              <input v-model="form.lastname"  type="text" class="reset-input" placeholder="姓氏">
            </div>

            <p class="label">联系电话</p>
            <div class="input-phone">
              <div @click="telPicker = true">
                <img src="@/assets/public/icon_jiananda@2x.png" alt="canada" v-if="form.phoneType == '1'">
                <img src="@/assets/public/china.png" alt="canada" v-else>
                <span>+{{form.phoneType}}</span>
              </div>
              <input v-model="form.phone" type="text" >
            </div>

            <div class="footer">
              <van-button @click="onNext" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">提交审核</van-button>
              <!-- <van-checkbox v-model="agree" icon-size="12px" checked-color="#F03C18"  style="margin-top:10px">
                <p class="times">阅读并同意 <span>《成为合伙人规则条件》</span></p>
              </van-checkbox>  -->
            </div>
          </div>
        </van-tab>
    </van-tabs>

    <!-- 申请状态 -->
    <div class="application-progress" v-else-if="status == 1">
      <img src="@/assets/payment/success@2x.png" alt="">
      <h3>等待审核中</h3>
      <p>您已成功提交申请，我们会尽快审核您提交的材料，请耐心等待审核结果。</p>
      <div class="back" style="margin-top:50px" @click="$router.back()">返回</div>
    </div>
    <div class="application-progress" v-else-if="status == 2">
      <img src="@/assets/payment/success@2x.png" alt="">
      <h3>审核通过</h3>
      <p>您的申请已通过</p>
      <div class="back" style="margin-top:50px" v-if="apply_type != 1" @click="status = 0;disable = true">升级企业合伙人</div>
      <div class="back" v-else @click="$router.back()">返回</div>
    </div>
    <div class="application-fail" v-else>
      <img src="@/assets/payment/fail.png" alt="">
      <h3>抱歉，审核未通过</h3>
      <p>您的申请未通过，我们已通过电话联系您，您还可以重新申请，请务必正确填写所需信息。</p>
      <div class="retry" @click="reApplicationPartnerHandle">重新申请</div>
      <div class="back" @click="$router.back()">返回</div>
    </div>

    <van-action-sheet v-model="telPicker" :actions="actions" cancel-text="取消" close-on-click-action @cancel="telPicker = false" @select="handlePhoneType" />
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { applicationPartner, reApplicationPartner, checkPartner, applicationqyPartner } from '@/api/zh/partner.js'

export default {
  name:'Partner',
  components:{ MHeader },
  data(){
    return {
      tab:0,          // tabs选中的面板
      top:44,
      form:{
        name:'',
        email:'',
        firstname:'',
        lastname:'',
        phoneType:1,
        phone:''
      },
      actions: [{ name: '1' }, { name: '86' },],
      telPicker:false,
      showPicker: false,
      agree:false,
      status: 0,
      disable: false,
      apply_type: 0,
      reset: false
    }
  },
  created() {
    this.checkPartnerHandle();
  },
  mounted(){
    this.tab = 0
    const clientWidth = document.body.clientWidth
    this.top = 44/375*clientWidth
  },

  methods:{

    // 选择区号
    handlePhoneType(it){
      this.form.phoneType = it.name
      this.telPicker = false
    },
    checkPartnerHandle() {
        // 判断是否已经成为合伙人
        checkPartner().then(res => {
          if(res) {
            // status  审核状态：0未申请 1审核中；2审核通过；3审核未通过
             this.status = res.status
             if (res.apply_type != undefined) {
                this.apply_type = res.apply_type
                this.status = 0
                this.disable = true
             }
          }
        })
    },
    // 提交
    onNext(){
      this.applicationPartnerHandle()
    },
    applicationPartnerHandle() {
      // 申请合伙人
      // if(!this.agree) {
      //   this.$notify({ type: 'warning', message: '阅读并同意《成为合伙人规则条件》'})
      //   return false
      // }
      if(this.form.name == '' && this.tab == 0) {
        this.$notify({ type: 'warning', message: '请填写企业名称'})
        return false
      }
      if(this.form.email == '') {
        this.$notify({ type: 'warning', message: '请填写邮箱'})
        return false
      }
      if(this.form.lastname == '') {
        this.$notify({ type: 'warning', message: '请填写名称'})
        return false
      }
      if(this.form.firstname == '') {
        this.$notify({ type: 'warning', message: '请填写姓氏'})
        return false
      }
      if(this.form.phone == '') {
        this.$notify({ type: 'warning', message: '请填写手机号'})
        return false
      }
      let params = {
        apply_type: this.tab == 0 ? 1 : 0,
        surname: this.form.firstname,
        name: this.form.lastname,
        corporate_name: this.form.name,
        email: this.form.email,
        area_code: this.form.phoneType,
        phone: this.form.phone
      }
      if(this.reset) {
        // 重新申请
        reApplicationPartner(params).then(res => {
          if(res) {
            this.$notify({ type: 'success', message: res.msg})
            this.status = 1
          }
        })
      } else {
        if (this.tab == 0) {
          applicationqyPartner(params).then(res => {
            if(res) {
              this.$notify({ type: 'success', message: res.msg})
              this.status = 1
            }
          })
        }else {
          applicationPartner(params).then(res => {
            if(res) {
              this.$notify({ type: 'success', message: res.msg})
              this.status = 1
            }
          })
        }
      }
    },
    reApplicationPartnerHandle() {
      this.reset = true
      this.status = 0
    }
  }
}
</script>

<style lang="less" scoped>
@import './partner.less';
</style>