<template>
  <div class="m-header">
    <img @click="handleBack" src="@/assets/order/icon-fanhui@2x.png" alt="back">
    <p>{{title}}</p>
    <span><slot name="right"></slot></span>
  </div>
</template>

<script>
export default {
  name: "OrderHeader",
  props: ['title'],
  methods: {
    handleBack(){
      if(this.$route.query.back) {
        this.$router.push(`/zh/${this.$route.query.back}`)
      } else {
        let path = localStorage.getItem('_back')
        if(path) {
          localStorage.removeItem('_back')
          this.$router.push(path)
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.m-header {
  width: 100%;
  height: 44px;
  background-color: #fff;
  padding: 0 12px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  &>img:nth-of-type(1){
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 8;
  }
  &>span {
    position: relative;
    z-index: 8;
    min-width: 35px;
    &>img{
      width: 30px;
      height: 30px;
    }
    &>span,&>span>a{
      font-size:16px;
      font-weight:500;
      color: #333;
    }
  }
  &>p {
    font-size:18px;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:25px;
    letter-spacing:1px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 44px;
    padding: 0 80px;
  }
}
</style>